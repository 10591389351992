@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
* { 
  box-sizing: border-box;
}

.login-register-form {
  align-items: center;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
  padding-bottom: 20px;
  width: 50%;
}

.login-register-form img {
  margin: auto auto 10px auto;
  padding: 2em;
  width: 20em;
}

.login-register-form .input-fields {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.field {
  display: flex;
  justify-content: center;
  width: 80%;
}

.login-register-form .input-fields input {
  border: 1px solid #b9b7b7;
  border-radius: 2px;
  font-size: 16px;
  margin: 10px -2px;
  padding: 15px;
  width: 80%;
}

.login-register-form button {
  background-color: #0096ed;
  border: none;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  margin: 10px auto;
  padding: 15px 0;
  width: 80%;
}

.login-register-form a {
  color: #747677;
  font-size: 17px;
  margin: 20px;
  text-decoration: none;
}

.login-register-form input, .login-register-form button, .dashboard-container button {
  outline: none;
}

.check-password-error-container {
  text-align: left;
  width: 80%;
}

.warning-p {
  align-self: flex-start;
  color: #FF0000;
  font-size: 14px;
  margin: -5px 0 20px 0;
  padding: 0;
}

.dashboard-container {
  align-items: center;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  padding-bottom: 20px;
  width: 80%;
}

.logo-container img {
  padding: 10px;
  width: 20em;
}

.dashboard-container .dashboard-user-info {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 50px auto;
  width: 95%;
}

.dashboard-user-info .info-container {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: right;
  width: 60%;
}

.dashboard-user-info .logo-container {
  width: 40%;
}

.dashboard-container hr {
  color: #333333;
}

.dashboard-select {
  /* background-color: teal; */
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
}

.dashboard-select button {
  background-color: transparent;
  border: 1px solid #333333;
  border-radius: 5px;
  color: #333;
  cursor: pointer;
  font-size: 16px;
  margin: 10px auto;
  padding: 15px 0;
  min-width: 30%;
}

.dashboard-select button.active {
  background-color: #0096ed;
  border: 1px solid #0096ed;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  margin: 10px auto;
  padding: 15px 0;
}

.dashboard-select button:hover {
  background-color: transparent;
  color: #333333;
}

.dashboard-user-info p, .dashboard-user-info button {
  margin: 5px;
  padding: 5px;
}

.dashboard-user-info button {
  background-color: transparent;
  border: 1px solid #333333;
  border-radius: 5px;
  color: #333333;
  cursor: pointer;
  font-size: 16px;
}

.dashboard-user-info .info-container button:hover {
  background-color: #0096ed;
  color: #ffffff;
}

.dashboard-container .create-btn {
  background-color: #0096ed;
  border: 1px solid #0096ed;
}

.dashboard-container .create-btn:hover {
  color: #0096ed;
}

.dashboard-table {
  border-collapse: collapse;
  color: #333333;
  margin: 50px auto;
  width: 90%;
}

.dashboard-table th, .dashboard-table td {
  background-color: transparent;
  border: 2px solid #333333;
  padding: 10px;
  text-align: left;
}

@media all and (max-width: 767px) {
  .login-register-form {
    width: 100%;
  }

  .dashboard-select button {
    min-width: 80%;
  }

  .dashboard-user-info {
    justify-content: center;
  }

  .dashboard-user-info .info-container, .dashboard-user-info .logo-container {
    width: 100%;
  }

  .dashboard-user-info .info-container {
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .dashboard-user-info .logo-container {
    text-align: center;
  }

  .dashboard-user-info .logo-container img {
    margin-bottom: 20px;
    width: 400px;
  }
}

@media all and (max-width: 1024px) {
  .dashboard-container {
    width: 100%;
  }
}

@media all and (min-width: 1024px) {
  .login-register-form {
    width: 35%;
  }
}
body {
  font-family: 'Roboto', sans-serif;
}


h2 {
  color: rgba(0,0,0,0.87);
  font-weight: 600;
  margin-top: 0;
}

h4, h1, p {
  font-family: 'Roboto', 'Helvetica' sans-serif;

}


h4, th {
  color: rgba(0,0,0,0.59);
  font-weight: 400;
  min-width: 70px;
}

table {
  width: 100%;
}

td {
  text-align: center;  
}

th{
  font-size: 15px;
  width: 70px;
  text-align: center;
}



input[type="text"],
 input[type="tel"],
  input[type="email"],
  input[type="url"]  {
  min-width: 22em;
  max-width: 450px;
  background-color: #fff;
  padding-top: 20px;
}
input[type="file"] {
  display: none;
}

input:disabled, select:disabled, .Ambassador_MuiFilledInput-root__z5qU7.Ambassador_Mui-disabled__1Z9xk {
  background-color: #bee7ff !important;
}


.Ambassador_form__3VStg {
  background-color: #f5f5f5;
  border-color: #b9b7b7;
  max-width: 980px;
  padding: 10px;
}

.Ambassador_ages__302sZ {
  text-align: start;
  padding: 5px;
}

.Ambassador_languageContainer__15EVt {
  display: 'flex';
  justify-content: 'flex-end'; 
}



.Ambassador_etapa__1vOrG {
  width: 300px;
  text-align: start;
}
.Ambassador_select__26Y3s {
  background-color: #fff;
  min-width: 21em;
  max-width: 300px;
  height: 40px;
  line-height: 50px;
  padding-top: 0 !important;
}



.Ambassador_logo__30YYT {
  margin: 0 auto;
}

.Ambassador_selectSmall__10iLv {
  min-width: 250px;
}

.Ambassador_section__2TqBQ {
  margin-top: 3em;
}

.Ambassador_sectionRadioGroup__1SEnp {
  
  margin-bottom: 2em;
}

.Ambassador_sectionRadioLabel__2oXMb {
  font-size: 22px;
  margin-top: 2em;
}

.Ambassador_textField__1rl2R {
  width: 23.5em;
  background-color: #fff !important;
}

.Ambassador_addForeignLanguage__PWQAK {
  margin-left: 15px;
}

.Ambassador_alert__5kJ92 {
  color: red !important;
}

h1 {
  margin: 0;
}

fieldset {
  border: none !important;
}

h4 {
  margin: 10px 0
}
.Mui-disabled {
  background-color: #bee7ff;
}

.MuiFilledInput-root, .MuiFilledInput-root.Mui-focused {
  background-color: #fff;
}

.warning {
  color: red
}

.deleteImage {
  background-color: #0096ed !important;
  width: 20px  !important;
  height: 20px !important;
  font-size: 12px !important;
  line-height: 20px !important;
}

.chooseFileButton {
  background-color: #0096ed !important;
}

div.fileContainer {
  background-color: rgba(0,0,0,0);
  box-shadow: none;
  border: none;
  padding: 0;
  align-items: stretch;
}

button.chooseFileButton {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
              0px 2px 2px 0px rgb(0 0 0 / 14%),
              0px 1px 5px 0px rgb(0 0 0 / 12%);
  text-transform: uppercase;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500  !important;
  border-radius: 0 !important;
  padding: 20px;
  font-size: 0.875rem;
  letter-spacing: 0.02857em;
  line-height: 1.75;
}

div.uploadPictureContainer {
  width: 250px !important; 

}




