@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

body {
  font-family: 'Roboto', sans-serif;
}


h2 {
  color: rgba(0,0,0,0.87);
  font-weight: 600;
  margin-top: 0;
}

h4, h1, p {
  font-family: 'Roboto', 'Helvetica' sans-serif;

}


h4, th {
  color: rgba(0,0,0,0.59);
  font-weight: 400;
  min-width: 70px;
}

table {
  width: 100%;
}

td {
  text-align: center;  
}

th{
  font-size: 15px;
  width: 70px;
  text-align: center;
}



input[type="text"],
 input[type="tel"],
  input[type="email"],
  input[type="url"]  {
  min-width: 22em;
  max-width: 450px;
  background-color: #fff;
  padding-top: 20px;
}
input[type="file"] {
  display: none;
}

input:disabled, select:disabled, .MuiFilledInput-root.Mui-disabled {
  background-color: #bee7ff !important;
}


.form {
  background-color: #f5f5f5;
  border-color: #b9b7b7;
  max-width: 980px;
  padding: 10px;
}

.ages {
  text-align: start;
  padding: 5px;
}

.languageContainer {
  display: 'flex';
  justify-content: 'flex-end'; 
}



.etapa {
  width: 300px;
  text-align: start;
}
.select {
  background-color: #fff;
  min-width: 21em;
  max-width: 300px;
  height: 40px;
  line-height: 50px;
  padding-top: 0 !important;
}



.logo {
  margin: 0 auto;
}

.selectSmall {
  min-width: 250px;
}

.section {
  margin-top: 3em;
}

.sectionRadioGroup {
  
  margin-bottom: 2em;
}

.sectionRadioLabel {
  font-size: 22px;
  margin-top: 2em;
}

.textField {
  width: 23.5em;
  background-color: #fff !important;
}

.addForeignLanguage {
  margin-left: 15px;
}

.alert {
  color: red !important;
}

h1 {
  margin: 0;
}

fieldset {
  border: none !important;
}

h4 {
  margin: 10px 0
}