.Mui-disabled {
  background-color: #bee7ff;
}

.MuiFilledInput-root, .MuiFilledInput-root.Mui-focused {
  background-color: #fff;
}

.warning {
  color: red
}

.deleteImage {
  background-color: #0096ed !important;
  width: 20px  !important;
  height: 20px !important;
  font-size: 12px !important;
  line-height: 20px !important;
}

.chooseFileButton {
  background-color: #0096ed !important;
}

div.fileContainer {
  background-color: rgba(0,0,0,0);
  box-shadow: none;
  border: none;
  padding: 0;
  align-items: stretch;
}

button.chooseFileButton {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
              0px 2px 2px 0px rgb(0 0 0 / 14%),
              0px 1px 5px 0px rgb(0 0 0 / 12%);
  text-transform: uppercase;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500  !important;
  border-radius: 0 !important;
  padding: 20px;
  font-size: 0.875rem;
  letter-spacing: 0.02857em;
  line-height: 1.75;
}

div.uploadPictureContainer {
  width: 250px !important; 

}

